import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-scroll-header', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}


		bind() {
			const animatedLogoLanding = document.querySelector( 'body:not(.is-visited) .js-scroll-header__landing' );
			let isBlocked = false;
			let debounce;

			const handler = ( () => {
				let threshold = 160;
				let isScrolled = false;

				if ( animatedLogoLanding ) {
					threshold += animatedLogoLanding.offsetHeight;

					// check when animated logo is removed
					this.on( 'mr-animated-logo:removed-animated-logo', () => {
						threshold = 160;
					}, window );
				}

				return () => {
					if ( !isScrolled && window.scrollY > threshold ) {
						isScrolled = true;

						window.requestAnimationFrame( () => {
							this.el.classList.add( 'is-scrolled' );
						} );
					} else if ( isScrolled && window.scrollY <= threshold ) {
						isScrolled = false;

						window.requestAnimationFrame( () => {
							this.el.classList.remove( 'is-scrolled' );
						} );
					}
				};
			} )();

			this.on( 'scroll', () => {
				if ( isBlocked ) {
					clearTimeout( debounce );

					debounce = setTimeout( () => {
						handler();
					}, 128 );

					return;
				}

				isBlocked = true;

				handler();

				setTimeout( () => {
					isBlocked = false;
				}, 256 );
			}, window, {
				passive: true,
			} );

			handler();
		}
	},
} );
