import '@mrhenry/wp--bugsnag-config';
import { MrPlayPauseToggle, MrCurrentTime, manageNonConcurrentPlayback } from '@mrhenry/wp--mr-interactive';

import './modules/accordion';
import './modules/animated-logo';
import './modules/audio';
import './modules/scroll-header';
import './modules/slideshow';
import './modules/video';

customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );
customElements.define( 'mr-current-time', MrCurrentTime );
manageNonConcurrentPlayback();
